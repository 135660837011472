import Grid3ColsHeader from '../components/navbar/Grid3ColsHeader';
import WhiteNavbar from '../components/navbar/WhiteNavbar';
import BlackNavbar from "../components/navbar/BlackNavbar"
import DefaultSidebar from '../components/sidebars/DefaultSidebar';
import { useAppSelector } from '../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom'

const Navbar = () => {

    const location = useLocation();
    const navigate = useNavigate()

    const { auth: { preferredCity }, customer, cart } = useAppSelector(state => state)

    switch (location.pathname.toLowerCase()) {
        case "/cart": return <Grid3ColsHeader 
        title="Cart"
        goBackTo={()=>navigate("/")}
        />
        case "/customerorderhistory": return <WhiteNavbar
            title="Order History"
            shadow={false}
            goBackTo={() => navigate("/searchcustomer")}
        />
        case "/applycoupon": return <WhiteNavbar
            title="Apply Coupon"
            goBackTo={() => navigate("/cart")}
            />
        case "/addpoints": return <WhiteNavbar
            title="Add Points"
            goBackTo={() => navigate("/searchCustomer?fetchWT=false")} />
        case "/searchcustomer": return <WhiteNavbar title="Add Customer" goBackTo={() => navigate("/")} />
        case "/referralhistory": return <WhiteNavbar title="Referral History"
            shadow={false}
            goBackTo={() => navigate("/searchcustomer")} />
        // eslint-disable-next-line no-case-declarations
        case "/customeraddress": const name = customer?.customerDetails?.customer?.firstName + " " + customer?.customerDetails?.customer?.lastName
            return <WhiteNavbar
                title={name.length !== 1 ? name : 'Choose Address'}
                goBackTo={() => navigate("/searchCustomer?popup=true")} />
        case "/customeraddress/change": return <WhiteNavbar title="Address" goBackTo={() => navigate("/customeraddress")} />
        case "/customercontact/change": return <WhiteNavbar title="Contact Information" goBackTo={() => navigate("/searchcustomer?popup=true")} />
       
        case "/customerdetails/additionalinfo/change": return <WhiteNavbar 
        title="Additional Information" 
        goBackTo={() => navigate("/searchcustomer?popup=true")} 
        />

        case "/billtotal": return <WhiteNavbar
            title={`Bill Total: ₹${cart.subTotal.amount}`}
            icons="chevron-down"
            goBackTo={() => navigate("/cart")} />
        case "/orderconfirmation": return <BlackNavbar goBackTo="/" />
        case "/checkfordelivery": return <WhiteNavbar
            title="Select Location"
            goBackTo={() => navigate("/")} />
        case "/salesperson/orderlisting": return <WhiteNavbar
            title="Your Order History"
            shadow={false}
            goBackTo={() => navigate("/?popup=true")} />
        case "/salesperson/orderstatus": return <WhiteNavbar
            title="Order Status"
            goBackTo={() => navigate("/salesperson/orderlisting")} />
        case "/salesperson/insights": return <WhiteNavbar
            title="Insights"
            goBackTo={() => navigate("/?popup=true")} />
        case "/salesperson/yourcustomers": return <WhiteNavbar
            title="Your Customers"
            shadow={true}
            goBackTo={() => navigate("/?popup=true")} />
        case "/searchorder": return <WhiteNavbar title="Order Tracker" goBackTo={() => navigate("/?popup=true")} />
        
        case "/login": return null
        default: return (<div className="p-4 text-white flex justify-between items-center bg-darkBaseColor text-white100 w-screen">
            <div>
                <div className="flex items-center gap-2 ">
                    <i className="pi pi-map-marker" />
                    <p className="font-semibold">{preferredCity.pin}</p>
                    <i
                        onClick={() => navigate("/checkForDelivery")}
                        className="pi pi-angle-down"></i>
                </div>
                <p className="text-sm">{`${preferredCity.name}, ${preferredCity.state}`}</p>
            </div>
            <DefaultSidebar />
        </div>)
    }
}

export default Navbar