import { createAsyncThunk } from "@reduxjs/toolkit";
import { CREATE_CART, CLEAR_CART, UPDATE_CART_LINE_ITEM, UPDATE_CART_CUSTOMER, UPDATE_CART_SHIPPING_ADDRESS } from "../../gqlOperations/mutations";
import { client } from "../../gqlOperations/graphql";
import { CHECK_SERVICEABILITY, GET_CART } from "../../gqlOperations/queries";
import { Address } from "../../entities/Address";

const createCart = createAsyncThunk(
    'cart/createCart',
    async () => {
        let result
        try{
            result = await client.mutate({
                mutation: CREATE_CART,
                fetchPolicy : 'network-only'
            })
        } catch(e){
            console.error(e)
            throw e
        }

        return result
        
    }
)
const loadCart = createAsyncThunk(
    'cart/getCart',
    async (args, {getState}) => {
        try{
            const state : any = getState()
            const result = await client.query({
                query : GET_CART,
                variables : {
                    cartId : state.cart.cartId
                },
                fetchPolicy : 'no-cache'
            })
            return result
        } catch(e){
            console.error(e)
            throw e
        }
        
    }
)
const checkServiceability = createAsyncThunk(
    'cart/checkServiceability',
    async (args, {getState}) => {
        try{
            const state : any = getState()
            const result = await client.query({
                query : CHECK_SERVICEABILITY,
                variables : {
                    cartId : state.cart.cartId
                },
                fetchPolicy : 'network-only'
            })
            return result
        } catch(e){
            console.error(e)
            throw e
        }  
    }
)
const clearCart = createAsyncThunk(
    'cart/clearCart',
    async (payload: any, {getState}) => {
        const state : any = getState()
        return await client.mutate({
            mutation: CLEAR_CART,
            variables: {
                cartId: state.cart.cartId
            },
            fetchPolicy : 'network-only'
        })
    }
)
const updateLineItem = createAsyncThunk(
    'cart/updateLineItem',
    async (payload : {
        sku : string,
        quantity : number
    }, {getState}) => {
        const state : any = getState()
        return await client.mutate({
            mutation : UPDATE_CART_LINE_ITEM,
            variables : {
                cartId : state.cart.cartId,
                lineItemInput : {
                    sku : payload.sku,
                    quantity : payload.quantity
                }
            },
            fetchPolicy : 'network-only'
        })
    }
)
const addToCart = createAsyncThunk(
    'cart/addLineItemToCartAsync',
    async (payload : {
        sku : string
    }, {getState}) => {
        try{
        const state : any = getState()
        const res =  await client.mutate({
            mutation : UPDATE_CART_LINE_ITEM,
            variables : {
                cartId : state.cart.cartId,
                lineItemInput : {
                    sku : payload.sku,
                    quantity : 1
                } 
            },
            fetchPolicy : 'network-only'
        })

        return res
        } catch(e){
            console.log('dd')
            throw e
        }
        
    }
)
const addCustomerAndShippingAddressToCart = createAsyncThunk(
    'cart/addCustomerAndShippingAddressToCart',
    async (payload : {
        customerId : string,
        shippingAddress : Address
    }, {getState}) => {
        try{

            const state : any = getState()
            await client.mutate({
                mutation : UPDATE_CART_CUSTOMER,
                variables : {
                    cartId : state.cart.cartId,
                    customerId : payload.customerId
                }
            })

            const res =  await client.mutate({
                mutation : UPDATE_CART_SHIPPING_ADDRESS,
                variables : {
                    cartId : state.cart.cartId,
                    shippingAddress : {
                        id: payload.shippingAddress.id,
                        contactPersons: payload.shippingAddress.contactPersons,
                        firstLine: payload.shippingAddress.firstLine,
                        secondLine: payload.shippingAddress.secondLine,
                        landMark: payload.shippingAddress.landMark,
                        city: payload.shippingAddress.city,
                        district: payload.shippingAddress.district,
                        state: payload.shippingAddress.state,
                        pin: payload.shippingAddress.pin,
                        country: payload.shippingAddress.country
                    }
                },
                fetchPolicy : 'network-only'
            })



            return res
            } catch(e){
                console.log('dd')
                throw e
            } 
    }
)
const updateShippingAddress = createAsyncThunk(
    'cart/updateShippingAddress',
    async (payload : {
        shippingAddress : Address
    }, {getState}) => {
        try{
            const state : any = getState()
            const sdd = {...payload.shippingAddress}
            const res =  await client.mutate({
                mutation : UPDATE_CART_SHIPPING_ADDRESS,
                variables : {
                    cartId : state.cart.cartId,
                    shippingAddress : {
                        id: payload.shippingAddress.id,
                        contactPersons: payload.shippingAddress.contactPersons,
                        firstLine: payload.shippingAddress.firstLine,
                        secondLine: payload.shippingAddress.secondLine,
                        landMark: payload.shippingAddress.landMark,
                        city: payload.shippingAddress.city,
                        district: payload.shippingAddress.district,
                        state: payload.shippingAddress.state,
                        pin: payload.shippingAddress.pin,
                        country: payload.shippingAddress.country
                    }
                },
                fetchPolicy : 'network-only'
            })
    
            return res
            } catch(e){
                console.log('dd')
                throw e
            } 
    }
)
export const CartThunk = {
    createCart,
    clearCart,
    updateLineItem,
    addToCart,
    loadCart,
    addCustomerAndShippingAddressToCart,
    updateShippingAddress,
    checkServiceability
}