import { gql } from '@apollo/client'

export const CUSTOMER_ORDER_HISTORY = gql`query orderHistroy ($skip: Int!,$take: Int!, $customerId:String!){
  customerOrders(skip: $skip, take: $take, customerId: $customerId) {
    _id
   deliveryStatus
   testOrder
   status
   orderDate
   selectedPaymentMethod
   orderNumber
   referenceNumber,
   zohoSalesOrderId
   zohoSalesOrderNumber
   estimatedDeliveryDate
   paidAmount{
     currencyCode
     amount
   }
   customer {
    firstName
    lastName
  }
   netPayable{
     currencyCode
     amount
   }
   netTotal{
     currencyCode
     amount
   }
   paymentGatewayInfo{
     gatewayName
   }
   lineItems{
         merchandise{
           title
           id,
           price{
             currencyCode
             amount
           }
           mrp{
             currencyCode
             amount
           }
           sku
           variantTitle
         }
         quantity,
         discounts{
           isAutomatic
           amount{
             currencyCode
             amount
           }
           discountCode
           description
         }
       }
    shippingAddress {
         firstLine,
         secondLine,
         city
         district
         pin
         state
         country
     }
     payments{
     _id,
   }
     selectedPaymentMethod,
     discounts{
             isAutomatic
             amount {
                 currencyCode
                 amount
             }
             description
             discountCode
         }
   shipments{
         estimatedDeliveryDate,
         lineItems{
          merchandise{
            title
            sku
          }
          quantity
         }
         statusUpdates{
           title,
           date,
           description,
           statusType,
         },
         status,
         trackingUrl
   }
    salesChannelInfo{
        salesApplication,
        salesPerson{
            uid,
            displayName
        }
    }
 }
 }
`

export const NEAREST_HUB = gql`query warehouseInfo($pincode: String!) {
  warehouseDetails(pincode: $pincode){
   name,
   _id,
   createdAt,
   updatedAt,
   type,
   zohoWarehouseId,
   createdAt,
   updatedAt,
   shipsyHubCode,
   serviceAvailable,
   geoLocation{
     latitude
     longitude
   }
   address{
     name
     firstLine
     secondLine
     city
     district
     landMark
     pin
     state
     country
 }
 distance
 serviceDays
 serviceType
   }
 }`

export const CHECK_SERVICEABILITY = gql`query isServiceable( $cartId: String!){
  checkServiceability(cartId : $cartId)
}`

export const GET_REFERRALS = gql`query referralInfo($userId: String!) {
  referralInfo(userId: $userId){
   id
   timeStamp
   verificationStatus
   verificationMethod
   updatedAt
   refereeDetails{
     id,
     name,
     customerType,
     phoneNo,
     createdAt,
     referralCode
   }
   referrerDetails{
     id,
     name,
     customerType,
     phoneNo,
     createdAt,
     referralCode
   }
 }
 }`


export const GET_WALLET_INFO = gql`query mywallet($userId: String!){
  myWallet(userId: $userId){
    walletId,
    points,
    transactions {
      id
      walletId
      transactionConfig
      transactionType
      points
      referenceDocType
      referenceDocNo
      createdAtDateString
    }
    pendingTransactions {
      id
      walletId
      transactionConfig
      transactionType
      points
      referenceDocType
      referenceDocNo
      createdAtDateString
      executionDate
    }
  }
}`

export const GET_CUSTOMER_OFFERS = gql`
  query myOffers($userId: String!, $orderTotal: Float!, $customerBusinessType: String!) {
    myOffers(userId: $userId, orderTotal: $orderTotal, customerBusinessType: $customerBusinessType) {
      id
      code
      name
      description
      discountAmount
      validFrom
      validTill
      isApplicable
      referenceDocNumber
      referenceDocType
    }
  }
`
export const GET_SALES_STATS = gql`query salesStats(
  $fromDate: String!, $toDate:String
 ) {
     salesStats (
        fromDate: $fromDate,
        toDate: $toDate,
     ) {
     orderValue
     orderCount
     customersCreated
     }
   }`

export const FILTER_SALES_STATS = gql`query filterStats(
  $fromDate: String!, $toDate:String!
 ) {
     filterStats (
         fromDate: $fromDate,
        toDate: $toDate,
     ) {
     orderValue
     orderCount
     customersCreated
     }
   }`

export const GET_ALL_FEEDS = gql`
query feedListing($take: Int, $skip: Int, $pincode: String, $priceFilter: PriceFilterDto, $customerBusinessType: customerBusinessType){
  feedList(
      take: $take,
      skip: $skip,
      priceFilter: $priceFilter,
      pincode: $pincode,
      customerType: $customerBusinessType
  ){
      id,
      vendor,
      name,
      subCatreogry,
      media{
        of,
        contentType,
        url,
        _id,
     }  
      benefits{
          description,
          mediaCollection{
              name, 
              url,
              contentType,
          },
      },
      usage{
          description,
      },
      nutrition{
          fat,
          protein,
      },
      tags{
          key,
          value,
      },
      vendor,
      variants {
          _id,
          serviceability,
          sku,
          name,
          status,
          weight{
              value,
              uom,
          },
          description,
          comboComposition{
            sku,
            name,
            quantity,
            weight {
            value,
            uom,
                },
            price {
            
            amount
               }

        },
          media{
              of,
              contentType,
              url,
              _id,
          },
          price {
              currencyCode,
              amount,
          },
          mrp {
              currencyCode,
              amount,
          },
          stockStatus
      }
  }
}
`
export const GET_A_FEED = gql`
  query getFeedDetails($productId: String!) {
    feed(id: $productId) {
      id
      vendor
      name
      benefits {
        description
        mediaCollection {
          name
          url
          contentType
        }
      }
      usage {
        description
      }
      nutrition {
        fat
        protein
      }
      tags {
        key
        value
      }
      variants {
        sku
        name
        status
        weight {
          value
          uom
        }
        price{
          currencyCode
          amount
        }
        mrp {
          currencyCode
          amount
        }
        description
        media {
          of
          contentType
          url
          _id
        }
        stockStatus
      }
    }
  }
`
export const SEARCH_A_CUSTOMER = gql`
  query Customer($phoneNumber: String!) {
    customer(phoneNumber: $phoneNumber) {
      userId
      firstName
      lastName
      createdAt
      createdBy
      referralPoints,
      referralCount,
      orderCount,
      totalOrderValue,
      totalSaved
      fathersName
      businessType
      shippingAddresses {
        id
        firstLine
        secondLine
        city
        state
        pin
        country
        district
        landMark
        contactPersons{
          fullName
          phoneNumber
          relation
          nickName
        }
      }
      defaultShippingAddress {
        id
        firstLine
        secondLine
        city
        state
        pin
        country
      }
      phoneNumber
      email
    }
  }
`
export const GET_ALL_BHAINS = gql`query animalListing($take: Int, $skip: Int, $breed: [String!], $milkFilter: MilkingFilterDto, $priceFilter: PriceFilterDto, $pincode: Int, $geoLocation: String, $calving: [Int!]){
  animalList(
      take: $take,
      skip: $skip,
      breed: $breed,
      milkingFilter: $milkFilter,
      priceFilter: $priceFilter,
      pincode: $pincode,
      geoLocation: $geoLocation,
      calving: $calving
  ){
  prodId,
  id,
  animalType,
  tag,
  name,
  vendor,
  parturitionDate
  conceivementDate
  currentLocation{
      geoLocation{
			latitude,
        longitude
      }
      name,
      address{
        name
        firstLine
        city
        district 
        state
      }
      
      hubCode,
      type,
  }
  productTags{
    key,
    value,
  },
  price{
    currencyCode,
    amount,
  },
  mrp{
    currencyCode,
    amount,
  },
  gender,
  breed,
  dob,
  measurements{
    height{
      value,
      uom,
    },
    weight{
      value,
      uom,
    },
  },
  milkRecords{
    recordDate,
    weight{
      value,
      uom,
    },
  },
  mediaRecords{
    of,
    contentType,
    url,
  },
  expectedMilkYield{
      recordDate
      weight{
        value
        uom
      }
    },
  averageMilkYield{
     recordDate,
    weight{
      value,
      uom,
    },
  },
  averageMilkQuality{
    recordDate,
    fat,
    snf,
  },
  pregnancyInfo{
    calving,
    isPregnant,
    months,
  },
  conceivementDate,
}
}`

export const GET_A_BHAINS = gql`query getAnimalDetails($animalId: String!) {
  animal(animalId: $animalId) {
    id,
    prodId,
		animalType,
    tag,
    name,
    vendor,
    tag,
    productTags{
      key,
      value,
    },
    price{
      currencyCode,
      amount,
    },
    mrp{
      currencyCode,
      amount,
    },
    gender,
    breed,
    dob,
    measurements{
      height{
        value,
        uom,
      },
      weight{
        value,
        uom,
      },
    },
    currentLocation{
      geoLocation{
			  latitude,
        longitude
      },
      name,
      address{
        name,
        firstLine,
        city,
        district, 
        state,
      },
      hubCode,
      type,
    },
    milkRecords{
      recordDate,
      weight{
        value,
        uom,
      },
    },
    mediaRecords{
      of,
      contentType,
      url,
    }
    averageMilkYield{
       recordDate,
      weight{
        value,
        uom,
      },
    },
    expectedMilkYield{
      recordDate,
      weight{
        value,
        uom
      }
    },
    averageMilkQuality{
      recordDate,
      fat,
      snf,
    },
    pregnancyInfo{
      calving,
      isPregnant,
      months,
    },
  	conceivementDate,
  }
}`

export const GET_ORDER_CONFIRMATION = gql`query GetOrderDetails($orderId: String!) {
        OrderDetails (
            orderId: $orderId
        ) {
          _id
          deliveryStatus
          grossShippingCharge{
            amount
          }
          netShippingCharge{
            amount
          }
          orderNumber
          zohoSalesOrderNumber
          payments{
            paymentMethod
            amount{
              amount
              currencyCode
            }
            status
          }
          paymentGatewayInfo{
            gatewayName
          }
          status
          customer{
            firstName
            lastName
            phoneNumber  
          }
          lineItems{
            
            merchandise{
              title
              id,
              price{
                currencyCode
                amount
              }
              mrp{
                currencyCode
                amount
              }
              sku
              variantTitle
            }
            quantity,
            discounts{
              isAutomatic
              amount{
                currencyCode
                amount
              }
              discountCode
              description
            }
          }
          shippingAddress {
            firstLine,
            secondLine,
            city
            district
            pin
            state
            country
            landMark
            contactPersons {
              fullName
              phoneNumber
              relation
              nickName
            }
        }
          netTotal{
            currencyCode,
            amount
          },
          allowedPaymentMethods{
            method
          },
          paidAmount{
                currencyCode
                amount
              }
        selectedPaymentMethod,
        paymentLink
          orderDate,
            netPayable{
                amount
            }
            discounts{
                isAutomatic
                amount {
                    currencyCode
                    amount
                }
                description
                discountCode
            }
          estimatedDeliveryDate,
          referenceNumber,
          shipments{
            estimatedDeliveryDate,
            lineItems{
              quantity,
              merchandise{
                title
                id
                sku
                mrp{
                  currencyCode
                  amount
                }
                weight{
                  uom
                  value
                }
                price{
                  currencyCode
                  amount
                }
              }
            },
            statusUpdates{
              title,
              date,
              description,
              statusType,
            },
            status,
            trackingUrl
          },
          salesChannelInfo{
            salesApplication,
            salesPerson{
                uid,
                displayName
            }
          }
        }
    }`

export const GET_SALESPERSON_ORDER = gql`query myOrders($paginationInput: PaginationInput!) {
  myOrders(paginationInput: $paginationInput) {
        _id
      deliveryStatus
      testOrder
      orderDate
      estimatedDeliveryDate
      orderNumber
      estimatedDeliveryDate,
      referenceNumber,
      status
      zohoSalesOrderId
      zohoSalesOrderNumber
      customer {
        firstName
        lastName
      }
      shipments{
            estimatedDeliveryDate,
            statusUpdates{
              title,
              date,
              description,
              statusType,
            },
            status,
            lineItems{
              quantity,
              merchandise{
                title
                id
                sku
                mrp{
                  currencyCode
                  amount
                }
                weight{
                  uom
                  value
                }
                price{
                  currencyCode
                  amount
                }
              }
            },
            trackingUrl,
          }
      payments{
        _id,
      }
          paymentGatewayInfo{
            gatewayName
          }
          lineItems{
            merchandise{
              title
              id,
              price{
                currencyCode
                amount
              }
              mrp{
                currencyCode
                amount
              }
              sku
              variantTitle
            }
            quantity,
            discounts{
              isAutomatic
              amount{
                currencyCode
                amount
              }
              discountCode
              description
            }
          }
          shippingAddress {
            firstLine,
            secondLine,
            city
            district
            pin
            state
            country
        }
          netTotal{
            currencyCode,
            amount
          }
          paidAmount{
                currencyCode
                amount
              }
        selectedPaymentMethod,
        paymentLink
            discounts{
                isAutomatic
                amount {
                    currencyCode
                    amount
                }
                description
                discountCode
            }
        salesChannelInfo{
            salesApplication,
            salesPerson{
                uid,
                displayName
            }
          }
    }
 }`

 export const GET_ORDERS_BY_DATE = gql`query allOrders($paginationInput: PaginationInput!,
  $estimatedDeliveryDateStart: DateTime!, $estimatedDeliveryDateEnd: DateTime!) {
  allOrders(paginationInput: $paginationInput,
            estimatedDeliveryDateStart: $estimatedDeliveryDateStart,  
            estimatedDeliveryDateEnd: $estimatedDeliveryDateEnd,) {
        _id
      deliveryStatus
      testOrder
      orderDate
      estimatedDeliveryDate
      orderNumber
      estimatedDeliveryDate,
      referenceNumber,
      status
      zohoSalesOrderId
      zohoSalesOrderNumber
      customer {
        firstName
        lastName
      }
      
      shipments{
        estimatedDeliveryDate,
        statusUpdates{
          title,
          date,
          description,
          statusType,
        },
        status,
        lineItems{
          quantity,
          merchandise{
            title
            id
            sku
            mrp{
              currencyCode
              amount
            }
            weight{
              uom
              value
            }
            price{
              currencyCode
              amount
            }
          }
        },
        trackingUrl,
      }
  payments{
    _id,
  }
          paymentGatewayInfo{
            gatewayName
          }
          lineItems{
            merchandise{
              title
              id,
              price{
                currencyCode
                amount
              }
              mrp{
                currencyCode
                amount
              }
              sku
              variantTitle
            }
            quantity,
            discounts{
              isAutomatic
              amount{
                currencyCode
                amount
              }
              discountCode
              description
            }
          }
          shippingAddress {
            firstLine,
            secondLine,
            city
            district
            pin
            state
            country
        }
          netTotal{
            currencyCode,
            amount
          }
          paidAmount{
                currencyCode
                amount
              }
        selectedPaymentMethod,
        paymentLink
            discounts{
                isAutomatic
                amount {
                    currencyCode
                    amount
                }
                description
                discountCode
            }
        salesChannelInfo{
            salesApplication,
            salesPerson{
                uid,
                displayName
            }
          }
    }
 }`

export const GET_SALESPERSON_CUSTOMER = gql`query mycustomers($paginationInput: PaginationInput!) {
  myCustomers(paginationInput: $paginationInput){
     userId,
     firstName,
     lastName,
     createdAt,
     referralPoints,
     referralCount,
     orderCount,
     totalOrderValue,
     totalSaved,
     shippingAddresses{
        id,
        firstLine,
        secondLine,
        city,
        state,
        pin,
        country,
      },
     defaultShippingAddress{
        firstLine,
        secondLine,
        city,
        state,
        pin,
        country,
      },
     phoneNumber,
     email,
     }
    }`

export const GET_REDEEMABLE_WALLET_POINTS = gql`
    query WalletPointsInfo($cartId: String!, $customerId: String!) {
      getRedemableWalletPointsInfo(cartId: $cartId, customerId: $customerId) {
        redeemableWalletPoints
        cartPageInfoForWalletRedeemPolicy
        
      }
    }
  `
export const GET_ADDRESS_FROM_PINCODE = gql`query GetStateAndDistrictForPin ($pin : String!) {
  getStateDistrictForPin(pin: $pin){
      district
      pin
      state
      country
  }
}
`
export const GET_CART=gql`query getCart( $cartId: String!) {
  cartDetails( cartId: $cartId){
    userId
    cartId
    createdAt
    serviceAvailable
    updatedAt
    customerBusinessType
    shippingAddress{
      id
      firstLine
      secondLine
      city
      state
      pin
      country
      district
      landMark
      contactPersons{
        fullName
        phoneNumber
        relation
        nickName
      }
    }
    recommendations{
      text
    }
    grossShippingCharge{
      amount
    }
    netShippingCharge{
      amount
    }
    lineItems{
      itemType
      quantity
      estimatedDeliveryDate
      merchandise{
        title
        id
        sku
        variantTitle
        mrp{
          currencyCode
          amount
        }
        weight{
          uom
          value
        }
        price{
          currencyCode
          amount
        }
      }
       discounts{
      discountCode
      amount {
        currencyCode
        amount
      }
      active
      isAutomatic
      description
    }
    }
    cartDiscounts{
      discountCode
      amount {
        currencyCode
        amount
      }
      active
      isAutomatic
      description
    }
    netTotal{
      amount
    }
    subTotal{
      amount
    }
    estimatedDeliveryDate
  }
}`